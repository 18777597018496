import * as React from 'react';

import { AboutListOfOtherTeam, AboutListOfCoreTeam } from '../';

import {
  AboutListTeamSectionContainer,
  AboutListTeamSectionHeader,
  AboutListTeamSectionHeaderExtendedTitle,
  AboutListTeamSectionHeaderExtendedSubtitle,
} from './styled';

export const AboutListTeamSection: React.FC = () => {
  return (
    <AboutListTeamSectionContainer>
      <AboutListTeamSectionHeader>
        <AboutListTeamSectionHeaderExtendedTitle>
          Amsterdam
        </AboutListTeamSectionHeaderExtendedTitle>
        <AboutListTeamSectionHeaderExtendedSubtitle>
          52.3676° N, 4.9041° E
        </AboutListTeamSectionHeaderExtendedSubtitle>
      </AboutListTeamSectionHeader>
      <AboutListOfCoreTeam />
      <AboutListTeamSectionHeader>
        <AboutListTeamSectionHeaderExtendedTitle>
          International
        </AboutListTeamSectionHeaderExtendedTitle>
        <AboutListTeamSectionHeaderExtendedSubtitle>
          +30 people
        </AboutListTeamSectionHeaderExtendedSubtitle>
      </AboutListTeamSectionHeader>
      <AboutListOfOtherTeam />
    </AboutListTeamSectionContainer>
  );
};
