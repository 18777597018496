import * as React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

import {
  TextWithBackgroundBlockContainer,
  TextWithBackgroundBlockText,
  ContentBlocksTitle,
  ContentBlocksParagraph,
  TextWithBackgroundBlockImage,
  TextWithBackgroundBlockImageMobile,
} from 'modules/contentBlocks';

export const CompanyValues: React.FC = () => {
  return (
    <TextWithBackgroundBlockContainer>
      <TextWithBackgroundBlockText>
        <ContentBlocksTitle maxWidth="100" margin="0">
          We stand for
        </ContentBlocksTitle>
        <ContentBlocksParagraph maxWidth="90" desktopMargin="1vh 0 0 0">
          <br />
          Restoring balance between mind and body, technology and humanity.<br /><br />
          Helping the curious seek balance.<br /><br />
          Celebrating and nurturing life on Earth.<br /><br />
          Shifting minds and cultures for the benefit of all.<br /><br />
          Business as a force for good.
        </ContentBlocksParagraph>
      </TextWithBackgroundBlockText>
      <TextWithBackgroundBlockImage>
        <StaticImage
          src="../../../../assets/images/person_standing_arms_desktop.png"
          alt="Woman smiling right"
          layout="fullWidth"
          className="text-with-background-block_image"
          imgStyle={{ borderRadius: '20px' }}
        />
      </TextWithBackgroundBlockImage>
      <TextWithBackgroundBlockImageMobile>
        <StaticImage
          src="../../../../assets/images/person_standing_arms_mobile.png"
          alt="Woman smiling right mobile"
          className="text-with-background-block_image"
          imgStyle={{ borderRadius: '20px' }}
        />
      </TextWithBackgroundBlockImageMobile>
    </TextWithBackgroundBlockContainer>
  );
};
