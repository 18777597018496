import * as React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

import {
  TextAndImageBlockExtraFieldsContainer,
  TextAndImageBlockExtraFieldsText,
  ContentBlocksParagraph,
  TextAndImageBlockExtraFieldsImage,
} from 'modules/contentBlocks';

import { ExtendedLogo } from './styled';

export const CompanyInformation: React.FC = () => {
  return (
    <TextAndImageBlockExtraFieldsContainer isReversed>
      <TextAndImageBlockExtraFieldsImage>
        <StaticImage
          src="../../../../assets/images/blurred_watch.png"
          alt="NOWATCH close up photo blurred"
          quality={70}
          placeholder="blurred"
          className="text-and-image-extra-fields-block_image"
        />
      </TextAndImageBlockExtraFieldsImage>
      <TextAndImageBlockExtraFieldsText alignText="flex-start">
        <ExtendedLogo />
        <ContentBlocksParagraph
          mobileMargin="2vh 0 5vh 0"
          desktopMargin="1vh 0 5vh 0"
        >
          Every day, just like you, NOWATCH asks the question: what’s the smartest way to feel calm, strong, and present?
        </ContentBlocksParagraph>
        <ContentBlocksParagraph
          mobileMargin="2vh 0 5vh 0"
          desktopMargin="1vh 0 5vh 0"
        >
          Founded in 2021, NOWATCH is a health innovation company for the curious. By blending cutting-edge technology with the leading science around mental health, we explore the frontiers of wellbeing.
        </ContentBlocksParagraph>
        <ContentBlocksParagraph
          mobileMargin="2vh 0 5vh 0"
          desktopMargin="1vh 0 5vh 0"
        >
          The NOWATCH Awareable is our flagship product, and the first step on our journey towards helping people recover from stress quickly and enjoy more meaningful lives – because Time is NOW.
        </ContentBlocksParagraph>
      </TextAndImageBlockExtraFieldsText>
    </TextAndImageBlockExtraFieldsContainer>
  );
};
