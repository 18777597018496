import styled from 'styled-components';

import { media } from 'styles/utils';
import LogoNowatch from 'vectors/internal/logo_nowatch.svg';

export const ExtendedLogo = styled(LogoNowatch)`
  width: 28vw;
  margin: 2vh 0 0 0;

  ${media.desktop`
    width: 11.5vw;
    margin: 5vh 0 1vh 0;
  `}
`;

