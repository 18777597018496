import styled from 'styled-components';

import { media } from 'styles/utils';
import { Heading, Text } from 'common/typography';

export const AboutListOfOtherTeamContainer = styled.div`
  display: grid;
  margin-bottom: 10vw;
  padding: 0 2vw;
  gap: 2vw;
  grid-auto-flow: row;
  grid-template-columns: 1fr 1fr;
  grid-row-gap: 5vw;

  ${media.desktop`
    grid-auto-flow: column;
    grid-template-columns: repeat(4, 1fr);
    margin-bottom: 5vw;
  `}
`;

export const AboutListOfOtherTeamRow = styled.div`
  display: flex;
  flex-direction: column;
`;


export const AboutListOfOtherTeamRowExtendedHeading = styled(Heading)`
  font-family: ${({ theme }) => theme.fonts.suisseIntl};
  font-size: 5vw;
  margin: 0;
  text-transform: none;
  line-height: 100%;
  font-weight: 400;

  ${media.desktop`
    font-size: 2.5vw;
  `}
`;

export const AboutListOfOtherTeamRowExtendedSubtitle = styled(Text)`
  font-size: 3.7vw;
  margin: 1vw 0 7vw 0;

  ${media.desktop`
    font-size: 1.5vw;
    line-height: 90%;
    margin: 1vw 0 5vw 0;
  `}
`;

export const AboutListOfOtherTeamRowName = styled(AboutListOfOtherTeamRowExtendedSubtitle)`
  margin: 0 0 2vw 0;
`;
