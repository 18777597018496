import * as React from 'react';

import {
  AboutListOfOtherTeamContainer,
  AboutListOfOtherTeamRow,
  AboutListOfOtherTeamRowExtendedHeading,
  AboutListOfOtherTeamRowExtendedSubtitle,
  AboutListOfOtherTeamRowName,
} from './styled';

const locations = [
  {
    title: 'Amsterdam (AMS)',
    subTitle: '52.3676° N, 4.9041° E',
    members: [
      /* 'Jeffrey - Software',
       * 'Sanne - Software',
       * 'Ruben - Software',
       * 'Jeroen - Software',
       * 'Marcel - Software',
       * 'Jodie - Software',
       * 'Sanjay - Software',
       * 'David - Brand',
       * 'Inge - Brand',
       * 'Ward - Brand',
       * 'Marjolein - Brand', */
    ],
  },
  {
    title: 'Jaipur (JAI)',
    subTitle: '26.9124° N, 75.7873° E',
    members: [
      /* 'Marco - Chief operations',
       * 'Azhar - Team manager',
       * 'Najju - Gemologist buyer',
       * 'Vinu - Quality control',
       * 'Zamil - Polishing master',
       * 'Alex - Stone cutter',
       * 'Aamir - Drop setter',
       * 'Javed - Drop setter',
       * 'Imran - Polisher',
       * 'Sehzad - Stone roundness',
       * 'Nisar - Stone smoothness', */
    ],
  },
  {
    title: 'Oulu (OU)',
    subTitle: '65.0121° N, 25.4651° E',
    members: [
      /* 'Antti - Technology',
       * 'Jukka - Hardware',
       * 'Erkki - Software',
       * 'Harri - Sourcing',
       * 'Leif - Hardware',
       * 'Kalle - Mechanics',
       * 'Joona - Mechanics',
       * 'Jussi - Tester',
       * 'Jaakko - Hardware',
       * 'Aku - Software',
       * 'Henri - Mechanics', */
    ],
  },
  {
    title: 'Los Angeles (LA)',
    subTitle: '34.0522° N, 118.2437° W',
    members: [
      /* 'Coming soon', */
    ],
  },
];

export const AboutListOfOtherTeam: React.FC = () => {
  return (
    <AboutListOfOtherTeamContainer>
      {locations.map((location) => (
        <AboutListOfOtherTeamRow key={location.title}>
          <AboutListOfOtherTeamRowExtendedHeading>
            {location.title}
          </AboutListOfOtherTeamRowExtendedHeading>
          <AboutListOfOtherTeamRowExtendedSubtitle>
            {location.subTitle}
          </AboutListOfOtherTeamRowExtendedSubtitle>
          {location.members.map((member) => (
            <AboutListOfOtherTeamRowName key={member}>
              {member}
            </AboutListOfOtherTeamRowName>
          ))}
        </AboutListOfOtherTeamRow>
      ))}
    </AboutListOfOtherTeamContainer>
  );
};
