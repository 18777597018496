import * as React from 'react';

import { Template, SupportBlock } from 'common/layout';
import { ContentBlocksContainer } from 'modules/contentBlocks';
import {
  AboutHeader,
  CompanyInformation,
  CompanyValues,
  OriginQuote,
  AboutListTeamSection,
  AboutPhilipsPartnership,
  AboutJaipurTeam,
  AboutBoardOfAdvisors,
  StoneCuttingFamily,
  StoneCreation,
  CertifiedCorporation,
} from 'modules/aboutContentBlocks';

const AboutPage: React.FC = () => {
  return (
    <Template>
      <AboutHeader />
      <ContentBlocksContainer>
        <CompanyInformation />
        <CompanyValues />
        <OriginQuote />
        <AboutBoardOfAdvisors />
        <AboutListTeamSection />
        <AboutPhilipsPartnership />
        <AboutJaipurTeam />
        <StoneCuttingFamily />
        <StoneCreation />
        <CertifiedCorporation />
        <SupportBlock />
      </ContentBlocksContainer>
    </Template>
  );
};

export default AboutPage;
