import * as React from 'react';

import {
  QuoteWithTextContainer,
  QuoteWithTextQuote,
  QuoteWithTextSentence,
  QuoteWithTextParagraph,
} from 'modules/contentBlocks';

export const OriginQuote: React.FC = () => {
  return (
    <QuoteWithTextContainer
      margin={{
        mobile: '16vw 2%',
        desktop: '8vw auto 14vw',
      }}
    >
      <QuoteWithTextQuote maxWidth="80">
        <QuoteWithTextSentence>Designed in Amsterdam</QuoteWithTextSentence>
        <QuoteWithTextSentence>Produced in Finland</QuoteWithTextSentence>
      </QuoteWithTextQuote>
      <QuoteWithTextParagraph maxWidth="70" desktopMargin="1vw 0 0 0">
        NOWATCH brings together excellence in Eastern and Western craftsmanship. With talent across three continents, we passionately combine leading health science, hardware technology, software innovation and ancient gemstone knowledge.
      </QuoteWithTextParagraph>
    </QuoteWithTextContainer>
  );
};
