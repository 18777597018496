import * as React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

import {
  HeaderContainer,
  HeaderImageContainer,
  HeaderTitle,
  HeaderMainWrapper,
} from 'modules/contentBlocks';

export const AboutHeader: React.FC = () => {
  return (
    <HeaderContainer>
      <HeaderMainWrapper>
        <HeaderImageContainer>
          <StaticImage
            src="../../../../assets/images/about_header_desktop.png"
            alt="Woman with NOWATCH"
            placeholder="blurred"
            layout="fullWidth"
            quality={80}
            className="header_image_desktop"
          />
          <StaticImage
            src="../../../../assets/images/about_header_mobile.png"
            alt="Woman with NOWATCH"
            placeholder="blurred"
            layout="fullWidth"
            quality={60}
            className="header_image_mobile"
          />
        </HeaderImageContainer>
        <HeaderTitle margin="0">About</HeaderTitle>
      </HeaderMainWrapper>
    </HeaderContainer>
  );
};
