import styled, { css } from 'styled-components';

import { media } from 'styles/utils';
import { Heading, Text } from 'common/typography';
import { ContentBlocksSection, ContentBlocksParagraph } from 'modules/contentBlocks';

type BoardOfAdvisorsParagraphProps = {
  maxWidth?: string;
}

export const BoardOfAdvisorsSection = styled(ContentBlocksSection)<BoardOfAdvisorsParagraphProps>`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;

  ${media.desktop<BoardOfAdvisorsParagraphProps>`
    max-width: 30vw;
    width: 80%;
    margin: 0;

    ${({ maxWidth }) => maxWidth && css`
      width: ${maxWidth}%;
      max-width: ${maxWidth}%;
    `}
  `}
`;

export const BoardOfAdvisorsItem = styled.div`
  width: 100%;
  margin: 8vw 0 0 0;
  order: 1;

  :nth-of-type(2) {
    order: 0;
  }

  ${media.tablet`
    width: 50%;
    margin: 4vw 0 0 0;
    :nth-of-type(2) {
      order: 0;
    }
  `}

  ${media.desktop`
    width: 33%;
    margin: 6vw 0 0 0;

    :nth-of-type(2) {
      order: 1;
    }
  `}
`;

export const BoardOfAdvisorsItemHeader = styled.div`
  align-items: center;
`;

export const BoardOfAdvisorsItemImageContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;

  .portrait {
    border-radius: 50%;
    width: 200px;
    height: 200px;
    user-select: none;
    pointer-events: none;
    will-change: transform;
  }
`;

export const BoardOfAdvisorsItemHeadingContainer = styled.div`
  padding-top: 24px;
`;

export const BoardOfAdvisorsItemExtendedHeading = styled(Heading)`
  font-family: ${({ theme }) => theme.fonts.suisseIntl};
  margin: 0;
  text-transform: none;
  font-weight: 400;

  font-size: 22px;
  line-height: 33px;
  letter-spacing: 0em;
  text-align: center;
`;

export const BoardOfAdvisorsItemExtendedSubtitle = styled(Text)`
  font-family: ${({ theme }) => theme.fonts.suisseIntl};
  font-size: 16px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: center;
  opacity: 0.45;
  margin: 0;
`;
