import * as React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

import {
  DoubleBlocksWrapper, DoubleBlocksContainer, DoubleBlockImage,
} from 'modules/contentBlocks';

export const StoneCreation: React.FC = () => {
  return (
    <DoubleBlocksWrapper>
      <DoubleBlocksContainer>
        <DoubleBlockImage>
          <StaticImage
            src="../../../../assets/images/polishing_stone.png"
            alt="Poloshing a stone"
            placeholder="blurred"
            layout="fullWidth"
            quality={80}
            className="double-image-block_first-image"
            imgStyle={{ borderRadius: '20px' }}
          />
        </DoubleBlockImage>
        <DoubleBlockImage>
          <StaticImage
            src="../../../../assets/images/single_stone.png"
            alt="A single stone"
            placeholder="blurred"
            layout="fullWidth"
            quality={50}
            className="double-image-block_second-image"
            imgStyle={{ borderRadius: '20px' }}
          />
        </DoubleBlockImage>
      </DoubleBlocksContainer>
    </DoubleBlocksWrapper>
  );
};

