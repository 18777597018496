
import * as React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

import {
  TextAndImageBlockExtraFieldsContainer,
  TextAndImageBlockExtraFieldsText,
  ContentBlocksParagraph,
  TextAndImageBlockExtraFieldsImage,
  ContentBlocksTitle,
} from 'modules/contentBlocks';

export const StoneCuttingFamily: React.FC = () => {
  return (
    <TextAndImageBlockExtraFieldsContainer isReversedMobile isReversed>
      <TextAndImageBlockExtraFieldsImage>
        <StaticImage
          src="../../../../assets/images/stone_cutting_family.png"
          alt="Stone-cutting family"
          quality={90}
          placeholder="blurred"
          className="text-and-image-extra-fields-block_image"
          imgStyle={{ borderRadius: '20px' }}
        />
      </TextAndImageBlockExtraFieldsImage>
      <TextAndImageBlockExtraFieldsText alignText="flex-start">
        <ContentBlocksTitle margin="5vh 0 0 0">
          Intentionally sourced minerals
        </ContentBlocksTitle>
        <ContentBlocksParagraph
          mobileMargin="2vh 0 5vh 0"
          desktopMargin="0 0 2vh 0"
          maxWidth="90"
        >
          All our gemstones are intentionally selected and cut to uphold the highest quality, traceability and sustainability standards. The stone-cutting process is done with fine precision by hand. We are proud to handle and approve each stone before it becomes a part of your treasured NOWATCH.
        </ContentBlocksParagraph>
      </TextAndImageBlockExtraFieldsText>
    </TextAndImageBlockExtraFieldsContainer>
  );
};
