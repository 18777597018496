import * as React from "react";
import { GatsbyImage } from "gatsby-plugin-image";
import { graphql, useStaticQuery } from "gatsby";

type TeamMember = {
  id: string;
  name: string;
  jobTitle: string;
  image: any;
};

export const AboutListOfCoreTeam: React.FC = () => {
  const data = useStaticQuery(graphql`
    query {
      allTeamJson {
        edges {
          node {
            id
            name
            jobTitle
            image {
              childImageSharp {
                gatsbyImageData(layout: CONSTRAINED, width: 400)
              }
            }
          }
        }
      }
    }
  `);

  const teamMembers: [TeamMember] = data.allTeamJson.edges.map(
    ({ node }) => node
  );

  return (
    <div className="grid grid-cols-3 sm:grid-cols-4 md:grid-cols-5 lg:grid-cols-6 xl:grid-cols-8 border-black border-l border-t">
      {teamMembers.map(({ id, name, jobTitle, image }, index) => (
        <div
          key={id}
          className="aspect-square border-black border-r border-b relative overflow-hidden flex"
        >
          {image && (
            <div className="absolute inset-0 transition duration-300 opacity-0 hover:opacity-100 grayscale w-full h-full">
              <GatsbyImage
                image={image.childImageSharp.gatsbyImageData}
                alt={name}
                className="object-cover w-full h-full"
              />
            </div>
          )}
          <div className="flex flex-col px-1 text-center w-full pt-[30%] 2xl:pt-[35%]">
            <h2 className="text-sm sm:text-lg mb-2 2xl:text-xl">{name}</h2>
            <h3 className="text-xs 2xl:text-sm uppercase tracking-wide text-stone-500">
              {jobTitle}
            </h3>
          </div>
        </div>
      ))}
    </div>
  );
};
