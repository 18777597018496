import * as React from 'react';

import PhilipsIcon from 'vectors/internal/logo_philips.svg';
import {
  DoubleBlocksWrapper,
  DoubleBlocksContainer,
  DoubleBlockSvg,
  DoubleBlockText,
} from 'modules/contentBlocks';

export const AboutPhilipsPartnership: React.FC = () => {
  return (
    <DoubleBlocksWrapper>
      <DoubleBlocksContainer>
        <DoubleBlockSvg color="heronPlume">
          <PhilipsIcon />
        </DoubleBlockSvg>
        <DoubleBlockText textAlign="left" color="albescentWhite">
          At NOWATCH we believe in partnerships. We are proud to work closely with Philips Biosensing technology. Together we can achieve more.
        </DoubleBlockText>
      </DoubleBlocksContainer>
    </DoubleBlocksWrapper>
  );
};
