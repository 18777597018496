import styled from 'styled-components';

import { media } from 'styles/utils';
import { Heading, Text } from 'common/typography';

export const AboutListTeamSectionContainer = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.colors.black};
  border-top: 1px solid ${({ theme }) => theme.colors.black};
  margin-bottom: 20vw;

  ${media.desktop`
    margin-bottom: 15vw;
  `}
`;

export const AboutListTeamSectionHeader = styled.div`
  padding: 0 2vw;
  margin: 5vw 0 5vw 0;

  ${media.desktop`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 3vw 0 3vw 0;
  `}
`;

export const AboutListTeamSectionHeaderExtendedTitle = styled(Heading)`
  font-family: ${({ theme }) => theme.fonts.suisseIntl};
  font-size: 5vw;
  margin: 0;
  text-transform: none;
  line-height: 100%;
  font-weight: 400;

  ${media.desktop`
    font-size: 2.5vw;
    width: 25vw;
  `}
`;

export const AboutListTeamSectionHeaderExtendedSubtitle = styled(Text)`
  font-size: 3.7vw;
  margin: 0;

  ${media.desktop`
    font-size: 1.5vw;
    line-height: 90%;
  `}
`;
