import * as React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

import {
  QuoteWithTextContainer,
  QuoteWithTextQuote,
  QuoteWithTextSentence,
  QuoteWithTextParagraph,
} from 'modules/contentBlocks';

import {
  BoardOfAdvisorsSection,
  BoardOfAdvisorsItemExtendedHeading,
  BoardOfAdvisorsItemImageContainer,
  BoardOfAdvisorsItemExtendedSubtitle,
  BoardOfAdvisorsItemHeader,
  BoardOfAdvisorsItem,
  BoardOfAdvisorsItemHeadingContainer
} from './styled';

export const AboutBoardOfAdvisors: React.FC = () => {
  return (
    <QuoteWithTextContainer
      margin={{
        mobile: '16vw 2%',
        desktop: '8vw auto 14vw',
      }}
    >
      <QuoteWithTextQuote maxWidth="80">
        <QuoteWithTextSentence>Board of Advisors</QuoteWithTextSentence>
      </QuoteWithTextQuote>
      <BoardOfAdvisorsSection maxWidth="80">
        <BoardOfAdvisorsItem>
            <BoardOfAdvisorsItemHeader>
              <BoardOfAdvisorsItemImageContainer>
                <StaticImage
                  src="../../../../assets/images/advisors/Chris.jpg"
                  alt="Chris Hall"
                  placeholder="blurred"
                  layout="fullWidth"
                  quality={80}
                  className="portrait"
                />
              </BoardOfAdvisorsItemImageContainer>
              <BoardOfAdvisorsItemHeadingContainer>
                <BoardOfAdvisorsItemExtendedHeading>
                  Chris Hall
                </BoardOfAdvisorsItemExtendedHeading>
                <BoardOfAdvisorsItemExtendedSubtitle>
                  Chair of the Executive Board
                </BoardOfAdvisorsItemExtendedSubtitle>
              </BoardOfAdvisorsItemHeadingContainer>
            </BoardOfAdvisorsItemHeader>
          </BoardOfAdvisorsItem>
        <BoardOfAdvisorsItem>
          <BoardOfAdvisorsItemHeader>
            <BoardOfAdvisorsItemImageContainer>
              <StaticImage
                src="../../../../assets/images/advisors/Nandini.jpg"
                alt="Dr Nandidi Tandon"
                placeholder="blurred"
                layout="fullWidth"
                quality={80}
                className="portrait"
              />
            </BoardOfAdvisorsItemImageContainer>
            <BoardOfAdvisorsItemHeadingContainer>
              <BoardOfAdvisorsItemExtendedHeading>
                Dr. Nandini Tandon
              </BoardOfAdvisorsItemExtendedHeading>
              <BoardOfAdvisorsItemExtendedSubtitle>
                Chair of the Scientific Board
              </BoardOfAdvisorsItemExtendedSubtitle>
            </BoardOfAdvisorsItemHeadingContainer>
          </BoardOfAdvisorsItemHeader>
        </BoardOfAdvisorsItem>
        <BoardOfAdvisorsItem>
          <BoardOfAdvisorsItemHeader>
            <BoardOfAdvisorsItemImageContainer>
              <StaticImage
                src="../../../../assets/images/advisors/BRIG.png"
                alt="H.R.H. Rajkumar Bhriguraj Singh"
                placeholder="blurred"
                layout="fullWidth"
                quality={80}
                className="portrait"
              />
            </BoardOfAdvisorsItemImageContainer>
            <BoardOfAdvisorsItemHeadingContainer>
              <BoardOfAdvisorsItemExtendedHeading>
                H.R.H. Rajkumar Bhriguraj Singh
              </BoardOfAdvisorsItemExtendedHeading>
              <BoardOfAdvisorsItemExtendedSubtitle>
                Chair
              </BoardOfAdvisorsItemExtendedSubtitle>
            </BoardOfAdvisorsItemHeadingContainer>
          </BoardOfAdvisorsItemHeader>
        </BoardOfAdvisorsItem>

        <BoardOfAdvisorsItem>
          <BoardOfAdvisorsItemHeader>
            <BoardOfAdvisorsItemImageContainer>
              <StaticImage
                src="../../../../assets/images/advisors/Remco.jpg"
                alt="Remco Wilcke"
                placeholder="blurred"
                layout="fullWidth"
                quality={80}
                className="portrait"
              />
            </BoardOfAdvisorsItemImageContainer>
            <BoardOfAdvisorsItemHeadingContainer>
              <BoardOfAdvisorsItemExtendedHeading>
                Remco Wilcke
              </BoardOfAdvisorsItemExtendedHeading>
              <BoardOfAdvisorsItemExtendedSubtitle>
                Member of the Board
              </BoardOfAdvisorsItemExtendedSubtitle>
            </BoardOfAdvisorsItemHeadingContainer>
          </BoardOfAdvisorsItemHeader>
        </BoardOfAdvisorsItem>
        <BoardOfAdvisorsItem>
          <BoardOfAdvisorsItemHeader>
            <BoardOfAdvisorsItemImageContainer>
              <StaticImage
                src="../../../../assets/images/advisors/David.jpg"
                alt="David Snellenberg"
                placeholder="blurred"
                layout="fullWidth"
                quality={80}
                className="portrait"
              />
            </BoardOfAdvisorsItemImageContainer>
            <BoardOfAdvisorsItemHeadingContainer>
              <BoardOfAdvisorsItemExtendedHeading>
                David Snellenberg
              </BoardOfAdvisorsItemExtendedHeading>
              <BoardOfAdvisorsItemExtendedSubtitle>
                Member of the Board
              </BoardOfAdvisorsItemExtendedSubtitle>
            </BoardOfAdvisorsItemHeadingContainer>
          </BoardOfAdvisorsItemHeader>
        </BoardOfAdvisorsItem>
      </BoardOfAdvisorsSection>
    </QuoteWithTextContainer>
  );
};
