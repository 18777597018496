import * as React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

import {
  DoubleBlocksWrapper,
  DoubleBlocksContainer,
  DoubleBlockImage,
  DoubleBlockText,
  ContentBlocksTitle,
} from 'modules/contentBlocks';

export const CertifiedCorporation: React.FC = () => {
  return (
    <DoubleBlocksWrapper margin="0 0 10vw">
      <DoubleBlocksContainer>
        <DoubleBlockText textAlign="left" color="albescentWhite">
          <ContentBlocksTitle>
            We’re using business as a force for good
          </ContentBlocksTitle>
          We are in the proces of getting our B corporation certification. If accepted, we will meet the highest verified standards of social and environmental performance, transparency and accountability.
        </DoubleBlockText>
        <DoubleBlockImage>
          <StaticImage
            src="../../../../assets/images/certified_corporation.png"
            alt="Certified Corporation"
            placeholder="blurred"
            layout="fullWidth"
            quality={100}
            className="double-image-block_second-image"
            imgStyle={{ borderRadius: '20px' }}
          />
        </DoubleBlockImage>
      </DoubleBlocksContainer>
    </DoubleBlocksWrapper>
  );
};
