import * as React from 'react';

import {
  QuoteWithTextContainer,
  QuoteWithTextQuote,
  QuoteWithTextSentence,
  QuoteWithTextParagraph,
} from 'modules/contentBlocks';

export const AboutJaipurTeam: React.FC = () => {
  return (
    <QuoteWithTextContainer
      margin={{
        mobile: '16vw 2%',
        desktop: '8vw auto 14vw',
      }}
    >
      <QuoteWithTextQuote maxWidth="90">
        <QuoteWithTextSentence>From Jaipur to Amsterdam</QuoteWithTextSentence>
      </QuoteWithTextQuote>
      <QuoteWithTextParagraph maxWidth="80" desktopMargin="1vw 0 0 0">
        Rajasthan is the northwestern region in India, famous for its long history of artisanal craftsmanship. Strategically located along ancient trade routes, it has developed into a production hub for the finest cloths and gemstones.<br /><br />
        In Jaipur, Rajasthan we work with family run businesses who preserve the inter-generational practices of gemstone knowledge and craft. We are honoured to have these craftspeople as part of the NOWATCH family.
      </QuoteWithTextParagraph>
    </QuoteWithTextContainer>
  );
};
